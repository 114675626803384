@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;1,300&display=swap');

body{

  font-family:Roboto; 
}

Header a{
text-decoration:none !important;
}

.App {
  text-align: center;
}



.App-logo {
  height: 4vh;
}

img.yelplogo {
  width: 80px;
  position: absolute;
  right: 8px;
  bottom: 8px;
}


img.ratingimg {
  position: absolute;
  top: 49px;
  width: 100px;
}

.cardtitle {
  position: absolute;
  color: #fff;
  top: 15px;
  left: 15px;
  font-size: 1.2em !important;
}

.cardtitle-cont{

  background: linear-gradient(transparent, #000000);
  display: block;
  width: calc(100% - 32px);
  height: 50px;
  position: absolute;
  top: 132px;
}

.InstaBox{

  margin-top: 20px;


}

@media (min-width: 320px){
.App-logo {
  height: 4vh;
  margin-top: 0px;
  pointer-events: none;
  margin-right: 40px;
}
}


@media (min-width: 900px){
  .App-logo {
    margin-right: 0px;
}
}


.whatToDo{
margin-top:60px!important;


}

.whatToDo > h1{
margin-bottom:0px!important;


}


.minMainBannerHeight{
  min-height:360px;
}

.GetThere{
  color: #fff !important;
}


.SuggestionsCont .btnshift{
  width: 100px;
    padding: 0;
    margin-top: 10px;
    position: absolute;
    right: 10px;
    top:35px;
    cursor:initial;
}

.closed{
  background-color: #f50000 !important;
  color: #fff !important;
}

  .SuggestionsCont p{
  font-size: 0.8rem;
}

.SuggestionsCont h2{
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0;
}
  .SuggestionsCont > div > div{

      position:relative;
  }


  .SuggestionsCont {

  margin-top:50px!important;
}

.suggestcardimg{
  height: 150px;
}


.mediaplacecard{
  padding-top: 0 !important;

}

#reveal, #lazy-load{
  height: 50vh;
  width: 100%;
  max-with:700px;
  margin: 0 auto;
  
}

@media (prefers-reduced-motion: no-preference) {

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.model-description{

  position: absolute;
  bottom:0px;
  font-size:0.8em;

}

#ar-button {
  background-image: url(././images/ic_view_in_ar_new_googblue_48dp.png);
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 12px 50%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  top: 50px;
  padding: 0px 16px 0px 40px;
  font-family: Roboto Regular, Helvetica Neue, sans-serif;
  font-size: 14px;
  color:#4285f4;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  border: 1px solid #DADCE0;
}

#ar-button:active {
  background-color: #E8EAED;
}

#ar-button:focus {
  outline: none;
}

#ar-button:focus-visible {
  outline: 1px solid #4285f4;
}

:not(:defined) > * {
  display: none;
}

model-viewer > #ar-failure {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 175px;
  display: none;
}

model-viewer[ar-tracking="not-tracking"] > #ar-failure {
  display: block;
}


.UserBox{

  display:none;
}

#menu-appbar .MuiPaper-elevation{
  top: 55px !important;
  width: 100%;
  border-radius: 0;
  left:0 !important;
  right: 0;
  max-width: 100%;
}


#menu-appbar li{
  border-bottom: 1px solid #ccc;
  padding-left:50px;
  text-decoration:none;
}

#menu-appbar ul a{
  color:rgba(0, 0, 0, 0.87);
  text-decoration:none !important;
}


.cslogo{
  width: 150px;
  margin: 0 auto 20px auto;
  display: block;

}

.css-13r4j3i .MuiTextField-root {
  margin: 2% !important;
  width: 46% !important;
}

.credits{
margin-top:20px !important;

}

.css-10759ka-MuiTypography-root {
  font-size: 3.50rem !important;
}

.formButtons{
  margin-top:10px;
  justify-content: flex-end;
}


.w100{
width:100% !important;
max-width:100% !important;
}

.BannerHome{
  background:url("/src/images/homecover.jpg");
    color: #fff !important;
    background-size: cover;
    background-position: center;
    margin-bottom: 55px;
    

}

.MediaCoverHome{
  background:url("/src/images/mediacover.jpg");
  background-size: cover;
  background-position: center;
  text-shadow: 0px 2px 2px black;
}

.BannerHome p{
  color:#fff !important;
  }

  
.BannerHome .MuiButton-outlined{
  color:#fff !important;
  border-color: #fff !important;
}


.BannerHome h1{
  color:#fff !important;
  }